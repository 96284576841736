import Styled from 'styled-components';

const AchievementTable = Styled.div`
  & table {
    > tbody {
      > tr {
        > td {
          &:first-child {
            > span {
              color: #8C8C8C;
              &:after {
                content: '.';
              }
            }
          }
        }
      }
    }
  }
`;

const DashWrapper = Styled.div`
  width: 100%;
  margin-right: 30px;
`;

const ViewMore = Styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;

  & * {
    margin-bottom: 0;
  }
`;

export default DashWrapper;
export { DashWrapper, ViewMore, AchievementTable };
