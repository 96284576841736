import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// UTILS
import { setSingularPlural } from '@utils';

// Components
import { Typography, Table, Title, Box, ProgressBar } from '@components';

const buildTableRows = (dataRows) => {
  if (dataRows?.length) {
    return dataRows.map((item, index) => (
      <tr key={item.id}>
        <td>
          <Typography color="#8C8C8C">{index + 1}.</Typography>
        </td>
        <td>
          <Typography>{item.title}</Typography>
        </td>
        <td>
          <ProgressBar progress={item.views_percentage} />
        </td>
        <td>
          <Typography align="right">
            {setSingularPlural({
              count: item.student_has_done,
              word: 'Acesso',
            })}
          </Typography>
        </td>
      </tr>
    ));
  }

  return (
    <tr>
      <td>
        <Typography>Ainda não há registros</Typography>
      </td>
    </tr>
  );
};

const MostAccessedContent = () => {
  const [dataTable, setDataTable] = useState([]);
  const { mostAccessedContent } = useSelector(
    (state) => state.dashboard.tables
  );

  useEffect(() => {
    if (mostAccessedContent?.length && !dataTable?.length) {
      const data = buildTableRows(mostAccessedContent);
      setDataTable(data);
    }
  }, [mostAccessedContent]);

  return (
    <>
      <Title
        title="Módulos"
        subtitle="Veja por quais módulos seus colaboradores mais demonstraram interesse em aprender. O número indica quantos colaboradores concluíram o módulo e a barra mostra a porcentagem que este número representa entre as licenças do seu plano."
      />
      <Box>
        <Table>
          <colgroup>
            <col span="1" style={{ width: '5%' }} />
            <col span="1" style={{ width: '30%' }} />
            <col span="1" style={{ width: '50%' }} />
            <col span="1" style={{ width: '15%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>Módulo</th>
              <th></th>
              <th></th>
              <th style={{ 'text-align': 'right' }}>Quantidade</th>
            </tr>
          </thead>
          <tbody>{dataTable}</tbody>
        </Table>
      </Box>
    </>
  );
};

export default MostAccessedContent;
export { MostAccessedContent };
