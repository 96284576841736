import React from 'react';
import PropTypes from 'prop-types';

// Components
import { FilterDate, FilterGroups } from '@components';

import { FiltersContainer } from './../Styled';

const ChartHeader = ({ getFilterDate, getFilterGroups }) => (
  <FiltersContainer>
    <FilterDate filter="graphicAccessFrequency" getFilterDate={getFilterDate} />
    <FilterGroups
      filter="graphicAccessFrequency"
      getFilterGroups={getFilterGroups}
    />
  </FiltersContainer>
);

ChartHeader.propTypes = {};
ChartHeader.defaultProps = {};

export default ChartHeader;
export { ChartHeader };
