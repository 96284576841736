import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Input, Button, Modal } from '@components';
import { LayoutDefault } from '@templates';
import { actions as userActions } from '@store/ducks/user';
import { LoginStyle } from './Styled';

const PasswordLost = () => {
  const [email, setEmail] = React.useState('');
  const [modal, setModal] = React.useState(false);
  const dispatch = useDispatch();
  const handleEmail = (value) => setEmail(value);
  const toggleModal = () => setModal(!modal);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Digite um e-mail válido')
        .required('É obrigatório informar seu e-mail')
        .test(
          'is-possible',
          'O e-mail digitado não é valido',
          (email) =>
            email &&
            email.indexOf('@') !== -1 &&
            email.substr(0, email.indexOf('@')).match(/[A-Za-z]+/)
        ),
    }),
    validateOnChange: true,
    onSubmit: async (values) =>
      dispatch(userActions.forgotPasswordRequest({ data: values })),
  });

  return (
    <LayoutDefault
      navigation={false}
      footer={false}
      menuUser={false}
      whiteBackground
      fullHeight
      centered
    >
      <Modal visible={modal} toggle={toggleModal}>
        <p style={{ maxWidth: '300px' }}>
          Enviamos um email para você com o link de redefinição de senha.
        </p>
      </Modal>
      <LoginStyle>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <Input
              id="email"
              name="email"
              variant="inside-label"
              label="Email"
              placeholder="nome.sobrenome@company.com"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue('email', e.target.value)}
              status={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : null
              }
            />
            <p>
              Um link para redefinir sua senha será enviado para o e-mail
              cadastrado em sua conta.
            </p>
            <Button block bigger variant="primary" type="submit">
              Resetar minha senha
            </Button>
            <Button href="/login" variant="text">
              Voltar
            </Button>
          </form>
        </div>
      </LoginStyle>
    </LayoutDefault>
  );
};

export default PasswordLost;
export { PasswordLost };
