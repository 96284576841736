import React from 'react';
import PropTypes from 'prop-types';

// UTIL
import { getDateMonthYear } from '@utils';

// STYLE
import { TooltipContainer } from './../Styled';

const ChartTooltip = ({ point }) => {
  const { accessFrequency, date } = point.data;
  const dateFormat = getDateMonthYear(date);

  return (
    <TooltipContainer>
      <b>{dateFormat}</b>
      <hr />
      <span>
        <i>{accessFrequency + ' '}</i>Frequência de acesso
      </span>
    </TooltipContainer>
  );
};

ChartTooltip.propTypes = {
  point: PropTypes.shape({
    color: PropTypes.string.isRequired,
    data: PropTypes.shape({
      accessFrequency: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

ChartTooltip.defaultProps = {
  point: {
    color: '',
    data: {
      accessFrequency: '',
      date: '',
    },
  },
};

export default ChartTooltip;
