import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// WIDGET BODY
import { Title } from '@components';
import widgetBody from './widgetBody';

// COMPONENTS
import Widget from './Widget';

// STYLES
import {
  SummaryStyle,
  WidgetsRow,
  WrapperSkeleton,
  SkeletonOne,
  SkeletonTwo,
  SkeletonThree,
} from './Styled';

const Summary = () => {
  const { widgets, loading } = useSelector((state) => state.dashboard);
  const loader = !!(loading || loading === undefined);

  const userReducer = useSelector((state) => state.user);

  const stepUserCompleted = !!(userReducer?.users?.length > 0);

  const widgetData = (key) => {
    const formatNumber = (num) => parseInt(num).toLocaleString();
    switch (key) {
      case 'Licenças adquiridas':
        return formatNumber(widgets.totalLicenses);
      case 'Horas estudadas':
        return formatNumber(
          widgets.sessionTime.substring(0, widgets.sessionTime.length - 6)
        );
      case 'Conquistas alcançadas':
        return formatNumber(widgets.achievements);
      case 'Certificados emitidos':
        return formatNumber(widgets.certificates);
    }
  };

  useEffect(() => {
    if (Object.keys(widgets).length && !loader) {
      widgetBody.forEach((widget) => {
        widget.data = widgetData(widget.title);
      });
    }
  }, [loader, widgetData, widgets]);

  return (
    <SummaryStyle>
      <Title
        title="Resumo"
        subtitle="Veja aqui um resumo de informações importantes acumuladas desde a primeira assinatura."
      />
      <WidgetsRow>
        {widgetBody.map((widget, index) => {
          return stepUserCompleted ? (
            <Widget key={index} widget={widget} loader={loader} />
          ) : (
            <SkeletonSteps />
          );
        })}
      </WidgetsRow>
    </SummaryStyle>
  );
};

const SkeletonSteps = () => (
  <WrapperSkeleton>
    <SkeletonOne />
    <SkeletonTwo />
    <SkeletonThree />
  </WrapperSkeleton>
);

export default Summary;
export { Summary };
