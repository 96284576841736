import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  Button,
  FilterStatus,
  Search,
  Title,
  ShortPagination,
} from '@components';
import { LayoutDefault } from '@templates';

import GroupUsers from '@components/Filter/GroupUsers';

// Actions
import { actions as modalActions } from '@store/ducks/modal';
import { actions as groupActions } from '@store/ducks/group';
import { actions as userActions } from '@store/ducks/user';

// Containers
import IconUser from '@icons/user.svg';
import IconClose from '@icons/x.svg';
import TableUsers from './containers/TableUsers';

// Component Icon

// STYLE
import { TitleStyled, Tooltip, CloseSearch } from './Styled';

const ManageUsers = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => ({
    userData: state.user.userData,
  }));
  const [inputSearch, setInputSearch] = useState('');
  const [filterStatus, setFilterStatus] = useState([]);
  const [dataTable, setDataTable] = useState();

  const { visible } = useSelector((state) => state.modal);
  const getAllUsers = useCallback(() => {
    dispatch(userActions.getAllUsersRequest());
  }, [dispatch]);

  useEffect(() => {
    getAllUsers();
    dispatch(groupActions.getAllGroupsRequest());
  }, [dispatch, getAllUsers]);

  const openModal = useCallback(() => {
    return dispatch(
      modalActions.open({ type: 'ADD_USER', props: 'NO_FILTER' })
    );
  }, [dispatch]);

  const submitSearch = useCallback(
    (e) => {
      e.preventDefault();
      if (inputSearch) {
        dispatch(userActions.getAllUsersRequest({ search: inputSearch }));
      }
    },
    [dispatch, inputSearch]
  );

  const handleSearch = useCallback(
    ({ target }) => {
      if (!target.value) {
        getAllUsers();
      }

      setInputSearch(target.value);
    },
    [setInputSearch]
  );

  const getFilterStatus = useCallback(
    (data) => {
      setFilterStatus(data);
    },
    [setFilterStatus]
  );

  const [filterGroupStatus, setFilterGroupStatus] = useState([]);

  const getFilterGroups = useCallback(
    (groups) => {
      if (visible) return;

      setFilterGroupStatus(groups);
    },
    [visible]
  );

  const closeSearch = () => {
    setInputSearch('');
    getAllUsers();
  };

  return (
    <LayoutDefault
      title="Gestão de usuários - Wise Up Online Corporate"
      topButton={!!dataTable?.length}
    >
      <TitleStyled>
        <Title
          as="h1"
          title="Gestão de usuários"
          subtitle="Consulte informações relevantes sobre todos os usuários cadastrados, além de poder adicionar novo e alterar o status da licença de cada um."
        />
      </TitleStyled>
      <Tooltip>
        <Button
          variant="primary"
          leftIcon={<IconUser />}
          onClick={openModal}
          disabled={!userData || !userData?.rules?.user_create}
        >
          Adicionar Usuário
        </Button>
        <FilterStatus getFilterStatus={getFilterStatus} />
        <GroupUsers getFilterGroups={getFilterGroups} getType="array" />

        <div style={{ position: 'relative', width: '100%' }}>
          <Search
            value={inputSearch}
            onChange={handleSearch}
            onSubmit={submitSearch}
            placeholder="Buscar por nome de usuário ou matricula"
            withSubmit
          />

          {inputSearch && (
            <CloseSearch type="button" onClick={() => closeSearch()}>
              <IconClose />
            </CloseSearch>
          )}
        </div>

        <ShortPagination total={dataTable?.length} rightSide />
      </Tooltip>

      <TableUsers
        filterStatus={filterStatus}
        filterGroups={filterGroupStatus}
        search={inputSearch}
        dataTable={dataTable}
        setDataTable={setDataTable}
      />
    </LayoutDefault>
  );
};

export default ManageUsers;
export { ManageUsers };
