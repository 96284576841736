import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

// UTILS
import { getGroups } from '@utils';

// STYLE
import { MenuDropDown } from '@components/DropDown';
import { Checkbox } from '@components/Field/Checkbox';

import { TooltipStyle } from '../Styled';

// COMPONENTS

const GroupUsers = ({ filter, getFilterGroups, fullChecked, getType }) => {
  const [groupsList, setGroupsList] = useState([]);
  const [fullCheck, setFullCheck] = useState(false);
  const { group } = useSelector((state) => state);

  const modalState = useSelector((state) => state.modal);

  const getGroupsSelects = useCallback(() => {
    return groupsList.filter((grp) => grp.selected === true);
  }, [groupsList]);

  const getSelected = useCallback(() => {
    const selects = getGroupsSelects();

    const arrGroupLabel = selects.map((grp) => grp.label);
    const strSeparatedComma = arrGroupLabel.join();

    const groupLabelArr = selects.map((grp) => grp.label);

    switch (getType) {
      case 'string':
        getFilterGroups(strSeparatedComma);
        break;
      case 'array':
        getFilterGroups(groupLabelArr);
        break;
      case 'object':
        getFilterGroups(selects);
        break;
      default:
        console.error('FilterGroups: getSelected key invalid');
        break;
    }
  }, [getFilterGroups, getGroupsSelects, getType]);

  const handleFullToggle = useCallback(
    ({ arrGroups = groupsList }) => {
      if (modalState.props === 'NO_FILTER' || modalState?.props?.id) return;

      const allToggleFilter = [...arrGroups];
      allToggleFilter?.forEach((obj) => (obj.selected = !fullCheck));
      setFullCheck(!fullCheck);
      setGroupsList(allToggleFilter);
    },
    [fullCheck, groupsList, modalState]
  );

  const handleSelect = useCallback(
    (label) => {
      const toggleFilter = [...groupsList];
      toggleFilter.forEach((obj) => {
        if (obj.label === label) obj.selected = !obj.selected;
      });

      if (toggleFilter.every((filter) => filter.selected === true)) {
        setFullCheck(true);
      } else {
        setFullCheck(false);
      }

      setGroupsList(toggleFilter);
    },
    [groupsList]
  );

  const renderOptions = useMemo(() => {
    return groupsList.map((option, index) => (
      <li key={index}>
        <Checkbox
          name={`${filter}-${option.label}`}
          label={option.label}
          checked={option.selected}
          onChange={() => handleSelect(option.label)}
        />
      </li>
    ));
  }, [filter, groupsList, handleSelect]);

  const renderAutoCheck = useMemo(() => {
    return (
      <li>
        <Checkbox
          name={`${filter}-todos`}
          label="Todos"
          checked={fullCheck}
          onChange={handleFullToggle}
        />
      </li>
    );
  }, [filter, fullCheck, handleFullToggle]);

  useEffect(() => {
    if (getFilterGroups) getSelected();
  }, [groupsList]);

  useEffect(() => {
    const { groups } = group;
    if (groups?.length && !groupsList?.length) {
      const arrGroups = getGroups(groups);
      if (fullChecked) return handleFullToggle({ arrGroups });
      return setGroupsList(arrGroups);
    }
  }, [group]);

  return (
    <>
      <TooltipStyle>
        <MenuDropDown top="50px" label="Filtro de Grupos">
          <span>Especifique um grupo</span>
          <ul>
            {renderAutoCheck}
            {renderOptions}
          </ul>
        </MenuDropDown>
      </TooltipStyle>
    </>
  );
};

GroupUsers.defaultProps = {
  filter: '',
  fullChecked: true,
  getType: 'string',
};

GroupUsers.prototypes = {
  filter: PropTypes.oneOf([
    'graphicStudyHours',
    'graphicFrequency',
    'graphicCompletedSteps',
    'ranking',
  ]),
  getType: PropTypes.oneOf(['string', 'array', 'object']),
  getFilterGroups: PropTypes.func,
};

export default React.memo(GroupUsers);
