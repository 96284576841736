import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Templates
import { LayoutDefault } from '@templates';

// Components
import { Input, Button, Typography } from '@components';

// Styles
import { StyleFirstAccess, ButtonStyled, StyledFirstAccess } from './Styled';

// Form & Validations
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { validateNameComplete } from '@utils/inputValid';

// Actions
import { actions as userActions } from '@store/ducks/user';

const FirstAccess = () => {
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.user);
  const { firstAccessData, loading } = userReducer;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email:
        firstAccessData && firstAccessData.email ? firstAccessData.email : '',
      name: firstAccessData && firstAccessData.name ? firstAccessData.name : '',
      surname:
        firstAccessData && firstAccessData.surname
          ? firstAccessData.surname
          : '',
      phone:
        firstAccessData && firstAccessData.phone ? firstAccessData.phone : '',
      password: '',
      passwordConfirmation: '',
      faToken:
        firstAccessData && firstAccessData.faToken
          ? firstAccessData.faToken
          : null,
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required('A senha é obrigatória')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*_#?&]{8,}$/,
          'Sua senha deverá ter no mínimo 8 dígitos, formada por letras e números'
        ),
      passwordConfirmation: Yup.string()
        .required('É obrigatório preencher a confirmação de senha')
        .oneOf(
          [Yup.ref('password'), null],
          'A confirmação de senha é obrigatória'
        ),
    }),
    onSubmit: async (values) => {
      const { email, password, passwordConfirmation, faToken } = values;
      dispatch(
        userActions.sendFirstAccessRequest({
          data: {
            email,
            newPassword: password,
            newPasswordConfirmation: passwordConfirmation,
            faToken,
          },
        })
      );
    },
  });

  return (
    <StyledFirstAccess>
      <LayoutDefault navigation={false} footer={false} menuUser={false}>
        <StyleFirstAccess>
          <div>
            <Typography as="h1" size="2.4em">
              Conclua seu cadastro
            </Typography>
            <Typography as="p" size="1.6em">
              Preencha o formulário para criar o seu login
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Input
                id="name"
                name="name"
                label="Nome"
                placeholder="Lindsey George"
                value={`${formik.values.name} ${formik.values.surname}`}
                disabled
              />
              <Input
                id="email"
                name="email"
                label="Email"
                placeholder="nome.sobrenome@company.com"
                value={formik.values.email}
                disabled
              />
              <Input
                id="phone"
                name="phone"
                mask="phone"
                label="Celular"
                placeholder="(00) 0000-0000"
                value={formik.values.phone}
                disabled
              />
              <Input
                id="password"
                name="password"
                type="password"
                label="Senha"
                placeholder="******"
                value={formik.values.password}
                onChange={(e) => formik.setFieldValue('password', e.target.value)}
                status={
                  formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : null
                }
              />
              <Input
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                label="Confirmação de senha"
                placeholder="******"
                value={formik.values.passwordConfirmation}
                onChange={(e) =>
                  formik.setFieldValue('passwordConfirmation', e.target.value)
                }
                status={
                  formik.errors.passwordConfirmation &&
                    formik.touched.passwordConfirmation
                    ? formik.errors.passwordConfirmation
                    : null
                }
              />
              <br />
              <ButtonStyled>
                <Button type="submit" loading={loading}>
                  ENTRAR
                </Button>
              </ButtonStyled>
            </form>
          </div>
        </StyleFirstAccess>
      </LayoutDefault>
    </StyledFirstAccess>
  );
};

export default FirstAccess;
export { FirstAccess };
