import styled from 'styled-components';

const ChartContainer = styled.div`
  width: 100%;
  height: 450px;
`;

const TooltipContainer = styled.div`
  background: #180a29;
  box-shadow: 0px 21.5304px 14.5186px rgba(0, 0, 0, 0.125183),
    0px 13.7721px 8.13901px rgba(0, 0, 0, 0.105),
    0px 8.37823px 4.32257px rgba(0, 0, 0, 0.0848175),
    0px 3.99035px 1.79872px rgba(0, 0, 0, 0.0590406);
  border: 1px solid #180a29;
  border-radius: 8px;
  width: 200px;
  height: auto;
  font-size: 12px;
  padding: 10px 15px 16px;
  color: #ffffff;
  margin-bottom: 10px;

  &:before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    border: 2px solid #00EB84;
    position: absolute;
    left: calc(50% - 8px);
    right: calc(50% - 8px);
    top: calc(100% + 6px);
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    /* bottom: -12px; */
    right: calc(50% - 5px);
    top: calc(100% - 10px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #180a29;
  }

  & i {
    font-style: normal;
    font-weight: bold;
  }

  > b {
    display: block;
    color: #b5b5b5;
    text-transform: uppercase;
    font-size: 10px;
  }

  > span {
    display: block;
    color: #ffffff;
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
  }

  hr {
    height: 1px;
    border-width: 0;
    color: #b5b5b5;
    background-color: #b5b5b5;
    margin-bottom: 12px;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  margin-bottom: 30px;

  > div {
    + div {
      margin-left: 15px;
    }
  }
`;

export const BoxSkeletonContainer = styled.div`
  div {
    border: none;
  }
`;

export const IconSkeletonContainer = styled.div`
  padding: 16px;

  svg {
    width: 100%;
  }
`;

export { FiltersContainer, TooltipContainer, ChartContainer };
