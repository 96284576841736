import Styled from 'styled-components';

const ButtonWrapper = Styled.div`
  display: flex;
  justify-content: center;
  margin: 0 60px 0;

  .primary button, .primary button:hover {
    font-weight: 800;
    min-width: auto;
    padding: .5rem 18px;
    background: ${(props) => props.stepsCompleted && '#DEDEDE'};
    border-color: ${(props) => props.stepsCompleted && '#DEDEDE'};

    svg {
      margin: 0 12px 0 0;
    }
  }

  > div {
    margin: 0;
  }
`;

const StepWrapper = Styled.div`
  display: flex;
  flex-direction: row;
`;

const ContainerStep = Styled.div`
  margin-bottom: 48px;
  margin-right: 16px;
  display: ${(props) => (props.visible ? 'inline-block' : 'none')};
  height: 179px;;
  width: 465px;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 10px;

  h6 {
    margin-top: 24px;
    margin-bottom: 7px;
    color: #333333;
    opacity: ${(props) => (props.stepsCompleted ? '0.4' : '1')};
  }

  p {
    max-width: 100%;
    margin-top: 8px;
    padding: 0 60px;
    color: #8C8C8C;
    font-size: 14px;
    line-height: 20px;
    opacity: ${(props) => (props.stepsCompleted ? '0.4' : '1')};
  }
`;

export { ContainerStep, StepWrapper, ButtonWrapper };
