import * as React from 'react';
import PropTypes from 'prop-types';

// COMPONENT
import { Header, Head, Footer, Sidebar, TopButton, Margin } from '@components';

// STYLE
import { TemplateStyle } from './Styled';

const LayoutDefault = (props) => {
  const { header, navigation, children, menuUser, footer, title, whiteBackground, fullHeight, centered, topButton } = props;
  return (
    <TemplateStyle width={navigation ? 'calc(100% - 230px)' : '100%'} whiteBackground={whiteBackground} header={header} fullHeight={fullHeight} centered={centered}>
      <Head title={title} />
      {header ? <Header menuUser={menuUser} /> : null}
      <div>
        {navigation ? <Sidebar /> : null}
        <main>
          {children}

          {topButton && (
            <footer>
              <TopButton variant={'outlined'} label={"Voltar para o topo"} rightIcon />
            </footer>
          )}
        </main>
      </div>

      {footer ? <Footer /> : null}
    </TemplateStyle>
  );
};

LayoutDefault.defaultProps = {
  header: true,
  title: 'Wise Up Online Corporate',
  navigation: true,
  menuUser: true,
  footer: false,
  whiteBackground: false,
  fullHeight: false,
  centered: false,
  topButton: true,
};

LayoutDefault.propTypes = {
  topButton: PropTypes.bool,
  header: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  fullHeight: PropTypes.bool,
  centered: PropTypes.bool,
  title: PropTypes.string,
  navigation: PropTypes.bool,
  menuUser: PropTypes.bool,
  footer: PropTypes.bool,
  children: PropTypes.element.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
};

export default LayoutDefault;
export { LayoutDefault };
