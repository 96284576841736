import React from 'react';

// Saga
import { useSelector } from 'react-redux';

// Components
import {
  Typography,
  Table,
  Title,
  Button,
  Box,
  EmptyData,
  Margin,
} from '@components';
// Styles

import { secondsToHHMMSS } from '@utils';

// Icons component
import IconArrowRight from '@icons/chevron-right.svg';
import { DashWrapper, ViewMore, StudyHoursTable } from './Styled';

const StudyHours = () => {
  const { studyHours, loading } = useSelector(
    (state) => state.dashboard.tables
  );
  const onLoading = Boolean(loading || loading === undefined);

  const buildTableCol = (content, alignRight = false) => (
    <td>
      <Typography size="1.4em" align={alignRight ? 'right' : 'left'}>
        {content}
      </Typography>
    </td>
  );

  const buildTableBody = (dataRows) => {
    return dataRows?.map((row, index) => {
      const format = secondsToHHMMSS(row.session, true);
      const formatTime = format.substr(0, format.length - 3);

      return (
        <tr key={index}>
          {buildTableCol(index + 1)}
          {buildTableCol(row.fullname)}
          {buildTableCol(formatTime, true)}
        </tr>
      );
    });
  };

  const renderTable = (
    <StudyHoursTable>
      <Table>
        <colgroup>
          <col span="1" style={{ width: '10%' }} />
          <col span="1" style={{ width: '60%' }} />
          <col span="1" style={{ width: '25%' }} />
        </colgroup>
        <tbody>{buildTableBody(studyHours?.slice(0, 10))}</tbody>
      </Table>
      <Margin top={1} />
      <ViewMore>
        <Button
          href="/home/horas-de-estudos"
          variant="text"
          rightIcon={<IconArrowRight />}
        >
          Ver mais
        </Button>
      </ViewMore>
    </StudyHoursTable>
  );
  return (
    <DashWrapper>
      <Title
        title="Horas de estudos"
        subtitle="Veja os top 10 colaboradores em horas dedicadas aos estudos, você também pode acessar a lista completa em ver mais."
      />
      <Box>
        {!studyHours?.length && !onLoading ? <EmptyData /> : renderTable}
      </Box>
    </DashWrapper>
  );
};

export default StudyHours;
export { StudyHours };
