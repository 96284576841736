import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import { actions as groupActions } from '@store/ducks/group';

// Components
import { LayoutDefault } from '@templates';
import { Title } from '@components';

// Containers
import { TableGroup, Actions } from './containers';

// Style
import { GroupsStyle, TitleWrapper } from './Styled';

const Groups = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(groupActions.getAllGroupsRequest());
  }, [])

  return (
    <LayoutDefault>
      <GroupsStyle>
        <TitleWrapper>
          <Title
            as="h1"
            title="Grupos"
            subtitle="Crie grupos de acordo com departamentos e times de sua empresa, caso queira agrupar os colaboradores de acordo com sua organização."
          />
        </TitleWrapper>
        <Actions />
        <TableGroup />
      </GroupsStyle>
    </LayoutDefault>
  );
};

export default Groups;
export { Groups };
