import Styled from 'styled-components';
import * as tokens from '@theme';

const WidgetsContainer = Styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  & span {
    font-size: 1.8vw;
    line-height: 24px;
    color: #231F20;
    font-weight: bold;
  }

  & p {
    font-size: 16px;
    line-height: 18px;
    color: #B5B5B5;
    margin: 0;
    margin-top: 3px;
  }

  @media all and (max-width: 1800px) {
    & p {
      font-size: 14px;
    }
  }
`;

const WidgetsIcon = Styled.div`
  background: ${({ background }) => background};
  border-radius: 12px;
  width: 48px;
  min-width: 48px;
  height: 48px;
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SummaryStyle = Styled.div`
  display: flex;
  flex-direction: column;
`;

const WidgetsRow = Styled.div`
  display: flex;

  > div {
    margin: 0 24px;
    width: 350px;
    min-width: 350px;

    @media all and (max-width: 1800px) {
      width: 25%;
      min-width: auto;
      margin: 0 10px;
      padding: 10px;
    }

    &:first-child {
      width: 40%;
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const WrapperSkeleton = Styled.div`
  width: 360px;
  height: 120px;
  background: #fff;
  border-radius: 12px;
  position: relative;
`;

export const SkeletonOne = Styled.div`
  position: absolute;
  width: 49px;
  height: 48px;
  left: 31px;
  top: 36px;
  background: #DEDEDE;
  border-radius: 12px;
`;

export const SkeletonTwo = Styled.div`
  position: absolute;
  width: 88px;
  height: 26px;
  left: 110px;
  top: 37px;
  background: #DEDEDE;
  border-radius: 4px;
`;

export const SkeletonThree = Styled.div`
  position: absolute;
  width: 160px;
  height: 11px;
  left: 111px;
  bottom: 37px;
  background: #F2F2F2;
  border-radius: 4px;
  max-width: 50%;
`;

export default SummaryStyle;
export { SummaryStyle, WidgetsRow, WidgetsContainer, WidgetsIcon };
