import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SortableTree, { getVisibleNodeCount } from 'react-sortable-tree';

// Actions
import { actions as groupActions } from '@store/ducks/group';

// Styles
import { GroupsStyle } from '../../Styled';

const TableGroup = () => {
  const dispatch = useDispatch();
  const { groups } = useSelector((state) => state.group);
  const count = getVisibleNodeCount({ treeData: groups });

  return (
    <GroupsStyle>
      <SortableTree
        treeData={groups}
        style={{ height: count * 62 }}
        onChange={(group) =>
          dispatch(
            groupActions.sendAddGroupRequest({
              data: group,
            })
          )
        }
      />
    </GroupsStyle>
  );
};

export default TableGroup;
