import Styled from 'styled-components';

const LoginStyle = Styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 60px - 12rem);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  label + label {
    margin: 16px 0;
  }

  > div {
    width: calc(100% - 10%);
    max-width: 400px;
    display: block;
  }

  & p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin: 20px auto;
    width: 300px;
  }

  & span {
    text-align: center;
    font-size: 24px;
    color: #333333;
    margin-bottom: 40px;
  }
`;

export default LoginStyle;
export { LoginStyle };
