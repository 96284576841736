import React from 'react';
// import styled from 'styled-components';

// Templates
import { LayoutDefault } from '@templates';

// Components
import { Typography } from '@components';

const PrivacyPolicies = () => {
  return (
    <LayoutDefault>
      <Typography as="h1" weight="bold" size="3.2em">
        Política de Privacidade
      </Typography>
    </LayoutDefault>
  );
};

export default PrivacyPolicies;
export { PrivacyPolicies };
