import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setSingularPlural, formatDateTime, secondsToHHMMSS } from '@utils';

import ArrowRight from '@icons/chevron-right.svg';

import {
  Switch,
  Pagination,
  TablePagination,
  Margin,
  Skeleton,
} from '@components';

import { actions as modalActions } from '@store/ducks/modal';
import { UserUpdateContainer, Status, FlexLink, Grid, Title } from './Styled';

const TableUsers = ({ filterStatus, filterGroups, search, dataTable, setDataTable }) => {
  // const [dataTable, setDataTable] = useState();
  const { users, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { visible } = useSelector((state) => state.modal);
  const modalState = useSelector((state) => state.modal);

  const handleSwitch = useCallback(
    ({ id, status }) => {
      if (!id) return;

      dispatch(
        modalActions.open({ type: 'INACTIVATE_USER', props: { status, id } })
      );
    },
    [dispatch]
  );

  const openModalEdit = useCallback(
    (userData) => {
      dispatch(modalActions.open({ type: 'EDIT_USER', props: userData }));
    },
    [dispatch]
  );

  const renderStatus = ({ status }) => {
    const userStatus = {
      ativo: 'Ativo',
      inativo: 'Inativo',
    };

    return <Status>{status ? userStatus.ativo : userStatus.inativo}</Status>;
  };

  const renderEdit = (user) => {
    const { name, id, status } = user;

    return (
      <UserUpdateContainer>
        <Switch
          name={name}
          value={status}
          onChange={() => handleSwitch({ id, status })}
        />
        <FlexLink
          onClick={() => {
            openModalEdit({ user, search });
          }}
        >
          <ArrowRight />
        </FlexLink>
      </UserUpdateContainer>
    );
  };

  const formatTimeHHMM = (number) => {
    const format = secondsToHHMMSS(number, true);
    return format.substr(0, format.length - 3);
  };

  const buildDataTable = useCallback(
    (arr = users) => {
      if (visible && modalState.type !== 'INACTIVATE_USER_SUCCESS') return;

      const data = arr?.map((user) => ({
        employee_registry: user.employee_registry,
        name: user.name,
        group: user.group,
        last_login: user.last_login_date
          ? formatDateTime(user.last_login_date)
          : '',
        study_hours: formatTimeHHMM(parseInt(user.session)),
        achievements: setSingularPlural({
          count: user.achievements,
          word: 'Módulo',
        }),
        status: renderStatus(user),
        edit: renderEdit(user),
      }));
      setDataTable(data);
    },
    [users, visible]
  );

  const handleFilter = () => {
    if (filterStatus?.none) {
      setDataTable([]);
      buildDataTable([]);
      return;
    }

    const result = users
      ?.filter((user) => {
        return filterGroups.includes(user?.group);
      })
      .filter((user) => {
        if (filterStatus?.all) {
          return user;
        }

        return filterStatus?.active ? user.status : !user.status;
      });

    buildDataTable(result);
  };

  useEffect(() => {
    handleFilter();
  }, [users]);

  useEffect(() => {
    if (users?.length) {
      handleFilter();
    }
  }, [filterGroups, filterStatus]);

  if (!dataTable?.length) {
    if (loading) {
      return (
        <>
          <Margin />
          <Skeleton width="100%" />
        </>
      );
    }

    if (Array.isArray(dataTable)) {
      return (
        <>
          <Margin />
          <Title>Nenhum resultado encontrado.</Title>
        </>
      );
    }
  }

  return (
    <>
      <Margin />
      <Grid>
        <span>Matrícula</span>
        <span>Usuário</span>
        <span>Grupo</span>
        <span>Último Acesso</span>
        <span>Horas de Estudo</span>
        <span>Conquistas</span>
        <span>Status</span>
        <span />

        <TablePagination grid rows={dataTable} />
      </Grid>
      <Margin />

      {dataTable?.length > 25 && <Pagination total={dataTable?.length} />}
    </>
  );
};

TableUsers.prototypes = {
  filterStatus: PropTypes.shape(),
  search: PropTypes.string,
};

export default React.memo(TableUsers);
export { TableUsers };
