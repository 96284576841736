import React from 'react';
import PropTypes from 'prop-types';

// UTIL
import { getDateMonthYear } from '@utils';

// STYLE
import { TooltipContainer } from './../Styled';

const ChartTooltip = ({ point }) => {
  const { completedSteps, date } = point.data;
  const dateFormat = getDateMonthYear(date);

  return (
    <TooltipContainer>
      <b>{dateFormat}</b>
      <hr />
      <span>
        <i>{completedSteps + ' '}</i>Steps Completados
      </span>
    </TooltipContainer>
  );
};

ChartTooltip.propTypes = {
  point: PropTypes.shape({
    color: PropTypes.string.isRequired,
    data: PropTypes.shape({
      completedSteps: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

ChartTooltip.defaultProps = {
  point: {
    color: '',
    data: {
      completedSteps: '',
      date: '',
    },
  },
};

export default ChartTooltip;
