import styled from 'styled-components';

export const TitleStyled = styled.div`
  width: 100%;
  max-width: 630px;
  margin-bottom: 30px;
  div {
    margin: 0;
    padding: 0;
    h1 {
      font-family: Avenir;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;
      color: #231f20;
      margin-bottom: 7px;
    }
    p {
      font-family: Avenir;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #8c8c8c;
    }
  }
`;

export const Tooltip = styled.div`
  width: 100%;
  // max-width: 1180px;
  display: flex;
  gap: 16px;
  align-content: center;

  > div {
    height: 48px;
    margin: 0;
  }

  > form {
    height: 42px;
    margin: 0;
  }
`;

export const CloseSearch = styled.button`
  position: absolute;
  right: 60px;
  top: 9px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
`;
