import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Lib
import { ResponsiveLine } from '@nivo/line';

// Components
import { Skeleton } from '@components';
import { ChartPlaceholder } from './../ChartPlaceholder';

// Partials
import ChartTooltip from './ChartTooltip';

// Settings Chart
const settingsChart = {
  colors: ['#00EB84'],
  tooltip: [
    { color: '#00EB84', label: 'Steps concluídos', id: 'completedSteps' },
  ],
  margin: { top: 20, right: 20, bottom: 100, left: 50 },
  curve: 'monotoneX',
  axisTop: null,
  axisRight: null,
  axisBottom: {},
  axisLeft: {},
  enableGridX: false,
  enableGridY: true,
  lineWidth: 2,
  pointSize: 0,
  pointColor: { from: 'color', modifiers: [] },
  pointBorderWidth: 10,
  pointBorderColor: 'black',
  pointLabelYOffset: 24,
  pointLabel: 'y',
  enableCrosshair: true,
  crosshairType: 'x',
  gridXValues: null,
  gridYValues: null,
  legends: [],
  areaOpacity: 0,
  useMesh: true,
  tooltip: {},
  theme: {
    crosshair: {
      line: {
        stroke: '#00EB84',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDasharray: 0,
        strokeLinecap: 'round',
      },
    },
  },
  xScale: { type: 'point' },
  yScale: {
    type: 'linear',
    stacked: true,
    min: 'auto',
    max: 'auto',
    reverse: false,
  },
};

const Chart = () => {
  const [dataChart, setDataChart] = useState([]);
  const { completedSteps } = useSelector((state) => state.dashboard.graphics);

  const dateDayAndMonth = (date) => {
    const split = date.split('-');
    return split[2] + '/' + split[1];
  };

  const isEmptyData = () => {
    const [obj] = dataChart;
    const isEmpty = obj.data?.filter((obj) => parseInt(obj.y));
    return Boolean(!isEmpty.length);
  };

  useEffect(() => {
    if (completedSteps?.length) {
      const data = completedSteps.map((obj) => ({
        x: dateDayAndMonth(obj.date),
        y: obj.steps,
        completedSteps: obj.steps,
        date: obj.date,
      }));
      const setData = [{ id: 'completedSteps', data }];
      settingsChart.axisBottom.tickValues = data.map(({ x }) => x);
      setDataChart(setData);
    }
  }, [completedSteps]);

  if (!dataChart.length)
    return <Skeleton width="100%" height="20px" rows={10} />;
  if (isEmptyData()) return <ChartPlaceholder />;

  return (
    <ResponsiveLine
      data={dataChart}
      margin={settingsChart.margin}
      xScale={settingsChart.xScale}
      yScale={settingsChart.yScale}
      curve={settingsChart.curve}
      axisTop={settingsChart.axisTop}
      axisRight={settingsChart.axisRight}
      axisBottom={settingsChart.axisBottom}
      axisLeft={settingsChart.axisLeft}
      enableGridX={settingsChart.enableGridX}
      enableGridY={settingsChart.enableGridY}
      colors={settingsChart.colors}
      lineWidth={settingsChart.lineWidth}
      pointSize={settingsChart.pointSize}
      pointColor={settingsChart.pointColor}
      pointBorderWidth={settingsChart.pointBorderWidth}
      pointBorderColor={settingsChart.pointBorderColor}
      pointLabelYOffset={settingsChart.pointLabelYOffset}
      enableCrosshair={settingsChart.enableCrosshair}
      crosshairType={settingsChart.crosshairType}
      pointLabel={settingsChart.pointLabel}
      gridXValues={settingsChart.gridXValues}
      gridYValues={settingsChart.gridYValues}
      legends={settingsChart.legends}
      useMesh={settingsChart.useMesh}
      areaOpacity={settingsChart.areaOpacity}
      theme={settingsChart.theme}
      tooltip={({ point }) => <ChartTooltip point={point} />}
      defs={[
        {
          id: 'completedSteps',
          type: 'linearGradient',
          colors: [
            { offset: 0, color: '#00EB84' },
            { offset: 100, color: '#ffffff' },
          ],
        },
      ]}
      fill={[{ match: '*', id: 'completedSteps' }]}
      animate={true}
      enableArea={true}
      areaOpacity={0.2}
    />
  );
};

export default Chart;
export { Chart };
