import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import { actions as dashboardActions } from '@store/ducks/dashboard';

// Partials
import ChartHeader from './ChartHeader';
import Chart from './Chart';

// Components
import { Title, Box } from '@components';

// Icons component
import IconDashboard from '@icons/rectangle.svg';

// Style
import { ChartContainer } from './../Styled';

// UTILS
import { getPreviousDate, getDateNowServer } from '@utils';

const ChartCompletedSteps = () => {
  const [filterDatePreviousDay, setFilterDatePreviousDay] = useState(0);
  const [filterGroups, setFilterGroups] = useState([]);

  const getFilterDate = (number) => setFilterDatePreviousDay(number);
  const getFilterGroups = (str) => setFilterGroups(str);
  const dispatch = useDispatch();

  const handleFilters = () => {
    const date_start = getPreviousDate(filterDatePreviousDay);
    const date_end = getDateNowServer();
    const groups = filterGroups;
    const params = {
      date_start,
      date_end,
      groups,
    };

    dispatch(dashboardActions.getGraphicCompletedStepsRequest({ params }));
  };

  useEffect(() => {
    if (filterDatePreviousDay || filterGroups) handleFilters();
  }, [filterDatePreviousDay, filterGroups]);

  return (
    <>
      <Title
        title="Steps concluídos"
        subtitle="Veja quantos steps seus colaboradores concluíram no Wise Up Online, você poderá consultar essa informação por período de tempo ou grupos."
      />
      <Box>
        <ChartContainer>
          <ChartHeader
            getFilterDate={getFilterDate}
            getFilterGroups={getFilterGroups}
          />
          <Chart />
        </ChartContainer>
      </Box>
    </>
  );
};

export default ChartCompletedSteps;
export { ChartCompletedSteps };
