import React from 'react';

// STYLE
import { ChartPlaceholderStyle } from './Styled';

const ChartPlaceholder = () => {
  return (
    <ChartPlaceholderStyle>
      <span>Ainda não temos dados suficientes para exibir.</span>
    </ChartPlaceholderStyle>
  );
}

export default ChartPlaceholder;
export { ChartPlaceholder };
