import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { actions as dashboardActions } from '@store/ducks/dashboard';

// Partials
import { Title, Box } from '@components';
import IconDashboard from '@icons/rectangle.svg';
import { getPreviousDate, getDateNowServer } from '@utils';
import GraphSkeleton from '@icons/graph-skeleton.svg';
import ChartHeader from './ChartHeader';
import Chart from './Chart';

// Components

// Icons component

// Style
import {
  ChartContainer,
  IconSkeletonContainer,
  BoxSkeletonContainer,
} from '../Styled';

// UTILS

const ChartStudyHours = () => {
  const [filterDatePreviousDay, setFilterDatePreviousDay] = useState(0);
  const [filterGroups, setFilterGroups] = useState([]);

  const getFilterDate = (number) => setFilterDatePreviousDay(number);
  const getFilterGroups = (str) => setFilterGroups(str);
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.user);
  const stepUserCompleted = !!(userReducer?.users?.length > 0);

  const handleFilters = () => {
    const date_start = getPreviousDate(filterDatePreviousDay);
    const date_end = getDateNowServer();
    const groups = filterGroups;
    const params = {
      date_start,
      date_end,
      groups,
    };

    dispatch(dashboardActions.getGraphicStudyHoursRequest({ params }));
  };

  useEffect(() => {
    if (filterDatePreviousDay || filterGroups) handleFilters();
  }, [filterDatePreviousDay, filterGroups]);

  return (
    <>
      <Title
        title="Horas de estudos"
        subtitle="Veja quantas horas de estudos seus colaboradores estão dedicando diariamente, você também poderá filtrar por períodos e grupos."
      />

      {stepUserCompleted ? (
        <Box>
          <ChartContainer>
            <ChartHeader
              getFilterDate={getFilterDate}
              getFilterGroups={getFilterGroups}
            />
            <Chart />
          </ChartContainer>
        </Box>
      ) : (
          <BoxSkeletonContainer>
            <Box>
              <IconSkeletonContainer>
                <GraphSkeleton />
              </IconSkeletonContainer>
            </Box>
          </BoxSkeletonContainer>
        )}
    </>
  );
};

export default ChartStudyHours;
export { ChartStudyHours };
