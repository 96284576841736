import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Button, Margin } from '@components';
import { LayoutDefault } from '@templates';

// COMPONENT ICON
import ChevronRight from '@icons/chevron-right.svg';

import { useFormik } from 'formik';
import * as Yup from 'yup';

// Actions
import { actions as userActions } from '@store/ducks/user';
import { LoginStyle } from './Styled';

const Login = () => {
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Digite um e-mail válido')
        .required('É obrigatório informar seu e-mail')
        .test(
          'is-possible',
          'O e-mail digitado não é valido',
          (email) =>
            email &&
            email.indexOf('@') !== -1 &&
            email.substr(0, email.indexOf('@')).match(/[A-Za-z]+/)
        ),
      password: Yup.string().required('É obrigatório informar sua senha'),
    }),
    validateOnChange: true,
    onSubmit: async (values) =>
      dispatch(userActions.loginRequest({ data: values })),
  });

  return (
    <LayoutDefault
      navigation={false}
      topButton={false}
      footer={false}
      menuUser={false}
      whiteBackground
      fullHeight
      centered
    >
      <LoginStyle>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <Input
              id="email"
              name="email"
              variant="inside-label"
              label="Email"
              placeholder="nome.sobrenome@company.com"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue('email', e.target.value)}
              status={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : null
              }
            />
            <Input
              id="password"
              name="password"
              label="Senha"
              variant="inside-label"
              placeholder="******"
              type="password"
              value={formik.values.password}
              onChange={(e) => formik.setFieldValue('password', e.target.value)}
              status={
                formik.errors.password && formik.touched.password
                  ? formik.errors.password
                  : null
              }
            />
            <br />
            <Button
              type="submit"
              loading={loading}
              block
              bigger
              variant="primary"
            >
              <b>LOGIN</b>
            </Button>
            <Margin top={1} />
            <Button
              href="/esqueci-minha-senha"
              variant="text"
              rightIcon={<ChevronRight />}
            >
              ESQUECI MINHA SENHA
            </Button>
          </form>
        </div>
      </LoginStyle>
    </LayoutDefault>
  );
};

export default Login;
export { Login };
