import styled from 'styled-components';

const ChartPlaceholderStyle = styled.div`
  position: relative;
  min-height: 375px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    top: 0;
    left: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 375px;
    background: url('/assets/icons/placeholder-chart.svg');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    opacity: .15;
  }

  > span {
    font-size: 1rem;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-weight: bold;
  }

`;

export default ChartPlaceholderStyle;
export { ChartPlaceholderStyle };
