import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Input, Button, Typography } from '@components';
import { LayoutDefault } from '@templates';

import { actions as userActions } from '@store/ducks/user';
import { LoginStyle } from './Styled';

const PasswordNew = () => {
  const [password, setPassword] = React.useState('');
  const [passwordCheck, setPasswordCheck] = React.useState('');
  const [modal, setModal] = React.useState(false);
  const router = useRouter();
  const { loading, userDataToken, isValidRenewPassToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handlePwd = (value) => setPassword(value);
  const handlePwdChk = (value) => setPasswordCheck(value);
  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    // dispatch(
    //   userActions.getUserPropertiesByToken({
    //     params: { faToken: router.query.token },
    //   })
    // );
  }, []);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required('É obrigatório informar sua senha'),
      passwordConfirmation: Yup.string()
        .required('É obrigatório informar a confirmação de senha')
        .oneOf(
          [Yup.ref('password'), null],
          'As senhas digitadas não estão iguais'
        ),
    }),
    validateOnChange: true,
    onSubmit: async (values) =>
      dispatch(
        userActions.resetPasswordRequest({
          data: { ...values, token: router.query.token },
        })
      ),
  });

  return (
    <LayoutDefault
      navigation={false}
      footer={false}
      menuUser={false}
      whiteBackground
      fullHeight
      centered
    >
      <LoginStyle>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <Typography as="span" weight="bold" size="1.8em">
              {userDataToken?.name && (
                <>
                  {userDataToken.name}
                  ,
                  <br />
                </>
              )}
              Insira sua nova senha
            </Typography>
            <Input
              id="password"
              name="password"
              label="Senha"
              variant="inside-label"
              placeholder="******"
              type="password"
              value={formik.values.password}
              onChange={(e) => formik.setFieldValue('password', e.target.value)}
              status={
                formik.errors.password && formik.touched.password
                  ? formik.errors.password
                  : null
              }
            />
            <Input
              id="passwordConfirmation"
              name="passwordConfirmation"
              label="Confirmação de Senha"
              variant="inside-label"
              placeholder="******"
              type="password"
              value={formik.values.passwordConfirmation}
              onChange={(e) =>
                formik.setFieldValue('passwordConfirmation', e.target.value)
              }
              status={
                formik.errors.passwordConfirmation &&
                  formik.touched.passwordConfirmation
                  ? formik.errors.passwordConfirmation
                  : null
              }
            />
            <Button type="submit" loading={loading} block bigger variant="blue">
              Enviar
            </Button>
          </form>
        </div>
      </LoginStyle>
    </LayoutDefault>
  );
};

export default PasswordNew;
export { PasswordNew };
