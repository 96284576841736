import Styled from 'styled-components';

export const StyleFirstAccess = Styled.div`
  position: relative;
  width: 100%;
  // height: calc(100vh - 60px - 12rem);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  > div {
    width: calc(100% - 10%);
    max-width: 400px;
    display: block;
  }

  & p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin: 20px auto;
    width: 300px;
  }

  & h1 {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
`;

export const ButtonStyled = Styled.div`
  button {
    width: 100%
  }
`

export const StyledFirstAccess = Styled.div`
  background-color: #f9f9f9
`

export default StyleFirstAccess;
