import Styled, { css } from 'styled-components';

const TemplateStyle = Styled.div`
  min-height: 100vh;
  position: relative;

  > div {
    display: flex;
    flex-flow: row;

    > main {
      > footer {
        background: #f9f9f9;
        position: relative;
        height: 80px;
      }

      ${props => props.centered && css`
        display: flex;
        align-items: center;
      `}
      width: ${({ width }) => width};
      padding: 32px 56px 0 56px;
      background: #f9f9f9;
      ${props => props.whiteBackground && css`
        background: #ffffff;
      `}

      ${props => {
    if (props.fullHeight) {
      if (props.header) {
        return css`height: calc(100vh - 70px);`
      }
      return css`height: 100vh;`
    }
  }}
      > .wrapper {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
`;

export default TemplateStyle;
export { TemplateStyle };
