// COMPONENTS ICONS
import IconChart from '@icons/chart.svg';
import IconClock from '@icons/clock.svg';
import IconAchievement from '@icons/achievement.svg';
import IconCertified from '@icons/certified.svg';

// COMPONENTS
import { Skeleton } from '@components';

const widgetBody = [
  {
    title: 'Horas estudadas',
    data: <Skeleton width="100%" height="30px" />,
    icon: <IconClock />,
    background: '#8166E5',
  },
  {
    title: 'Licenças adquiridas',
    data: <Skeleton width="100%" height="30px" />,
    icon: <IconChart />,
    background: '#27CF72',
  },
  {
    title: 'Conquistas alcançadas',
    data: <Skeleton width="100%" height="30px" />,
    icon: <IconAchievement />,
    background: '#FF6937',
  },
  {
    title: 'Certificados emitidos',
    data: <Skeleton width="100%" height="30px" />,
    icon: <IconCertified />,
    background: '#0BA1F7',
  },
];

export default widgetBody;
export { widgetBody };
