import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Icons
import ArrowRight from '@icons/arrow-right.svg';

// Templates
import { LayoutDefault } from '@templates';

import { actions as planActions } from '@store/ducks/plan';

import { Input } from '@components/Base';
import { Title, ProgressBar, Skeleton, Button, Margin } from '@components';
import {
  StyleMyPlan,
  Wrapper,
  TitleStyled,
  BoxContainer,
  FlexContainer,
  RightPosition,
  ProgressBarBox,
  TitleBox,
  ResumeStyled,
  InfoDataBox,
  SkeletonContainer,
  GridContainer,
  GridColumn,
} from './Styled';

const bigCoorporate = 'Plano Grandes Corporações';
const smallCoorporate = 'Plano Pequenas e Médias Empresas';

const MyPlan = () => {
  const dispatch = useDispatch();
  const dataPlan = useSelector((state) => state.plan);
  const [amountSubscriptions, setAmountSubscriptions] = useState('');

  const nextDate = useMemo(() => {
    if (dataPlan?.renewDate) {
      const [year, month, day] = dataPlan?.renewDate.split('-');
      return format(new Date(year, month - 1, day), "dd 'de' MMMM 'de' yyyy'", {
        locale: pt,
      });
    }

    return null;
  }, [dataPlan]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    window.location.href = `${process.env.CHECKOUT_CORP}/repurchase?contractId=${dataPlan.contractId}&qty=${amountSubscriptions}`;
  };

  const handleChange = (evt) => {
    let financialGoal = evt.target.validity.valid
      ? evt.target.value
      : amountSubscriptions;
    financialGoal =
      financialGoal.length > 5 ? amountSubscriptions : financialGoal;
    financialGoal = financialGoal === '0' ? amountSubscriptions : financialGoal;
    // financialGoal = Number(financialGoal) > 99999 ? 99999 : financialGoal;

    setAmountSubscriptions(financialGoal);
  };

  useEffect(() => {
    dispatch(planActions.planRequest({ isServer: false }));
  }, [dispatch]);

  return (
    <>
      <LayoutDefault
        title="Meu Plano - Wise Up Online Corporate"
        topButton={false}
      >
        <StyleMyPlan>
          <TitleStyled>
            {dataPlan?.plan?.type ? (
              <h1>
                {dataPlan.plan.type === 'GC' ? bigCoorporate : smallCoorporate}
              </h1>
            ) : (
              <h1>
                <Skeleton height="32px" />
              </h1>
            )}
            <p>
              Aqui você pode acompanhar todas as informações sobre o seu plano
              como data de
              <br />
              renovação, licenças em uso e disponiveis e compras anteriores.
            </p>
          </TitleStyled>

          <ResumeStyled>
            <Title
              title="Resumo"
              subtitle="Veja aqui um resumo das informações do seu plano."
            />
          </ResumeStyled>
          {dataPlan?.total ? (
            <Wrapper>
              <BoxContainer>
                <FlexContainer>
                  <div>
                    <TitleBox>EM USO</TitleBox>
                    <div>
                      <InfoDataBox>{dataPlan?.usage}</InfoDataBox>
                      <InfoDataBox> Assinaturas</InfoDataBox>
                    </div>
                  </div>
                  <RightPosition>
                    <TitleBox>DISPONÍVEL</TitleBox>
                    <div>
                      <InfoDataBox>
                        {dataPlan?.total - dataPlan?.usage}
                      </InfoDataBox>
                      <InfoDataBox> Assinaturas</InfoDataBox>
                    </div>
                  </RightPosition>
                </FlexContainer>
                <ProgressBarBox>
                  <ProgressBar progress={dataPlan?.usage || 0} height="16px" />
                </ProgressBarBox>
              </BoxContainer>

              <BoxContainer>
                <FlexContainer>
                  <div>
                    <TitleBox>VALIDADE DO PLANO</TitleBox>
                    <InfoDataBox>{dataPlan?.periodEnd}</InfoDataBox>
                  </div>
                  <RightPosition>
                    <TitleBox>PRÓXIMA RENOVAÇÃO</TitleBox>
                    <InfoDataBox>{nextDate || ''}</InfoDataBox>
                  </RightPosition>
                </FlexContainer>
                <ProgressBarBox>
                  <ProgressBar
                    progress={dataPlan?.plan?.progress || 0}
                    height="16px"
                  />
                </ProgressBarBox>
              </BoxContainer>
            </Wrapper>
          ) : (
            <SkeletonContainer>
              <Skeleton rows={2} height="112px" />
            </SkeletonContainer>
          )}

          <Margin top={3} />
          <ResumeStyled>
            <Title
              title="Gostaria de adquirir mais licenças"
              subtitle={`É bem simples de adquirir mais licenças para o seu plano Pequenas e Médias Empresas, basta selecionar a \nquantidade e confirmar os dados e prosseguir.`}
            />
          </ResumeStyled>

          <BoxContainer padding="0px 18px">
            <form onSubmit={handleSubmit}>
              <GridContainer>
                <GridColumn margin="auto">
                  <Input
                    type="text"
                    pattern="[0-9]*"
                    placeholder="Digite a quantidade desejada"
                    value={amountSubscriptions}
                    onChange={handleChange}
                  />
                </GridColumn>

                <GridColumn margin="auto">
                  <Button
                    type="submit"
                    disabled={!amountSubscriptions}
                    rightIcon={<ArrowRight />}
                  >
                    Continuar
                  </Button>
                </GridColumn>
              </GridContainer>
            </form>
          </BoxContainer>
        </StyleMyPlan>
      </LayoutDefault>
    </>
  );
};

export default MyPlan;
export { MyPlan };
