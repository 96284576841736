import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, Box } from '@components';

// STYLES
import { WidgetsContainer, WidgetsIcon } from './Styled';

const Widget = ({ widget }) => (
  <Box>
    <WidgetsContainer>
      <WidgetsIcon background={widget.background}>{widget?.icon}</WidgetsIcon>
      <div>
        <div>
          <span>{widget?.data}</span>
        </div>
        <div>
          <p>{widget?.title}</p>
        </div>
      </div>
    </WidgetsContainer>
  </Box>
);

Widget.propTypes = {
  body: PropTypes.shape({
    icon: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.element,
    ]),
  }),
};

Widget.defaultProps = {
  body: {
    icons: <></>,
    title: '',
    data: '',
  },
};

export default Widget;
