import React from 'react';
import PropTypes from 'prop-types';

// UTIL
import { getDateMonthYear } from '@utils';

// STYLE
import { TooltipContainer } from './../Styled';

const ChartTooltip = ({ point }) => {
  const { studyHours, date } = point.data;
  const dateFormat = getDateMonthYear(date);

  return (
    <TooltipContainer>
      <b>{dateFormat}</b>
      <hr />
      <span>
        <i>{studyHours + ' '}</i>Horas de estudos
      </span>
    </TooltipContainer>
  );
};

ChartTooltip.propTypes = {
  point: PropTypes.shape({
    color: PropTypes.string.isRequired,
    data: PropTypes.shape({
      studyHours: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

ChartTooltip.defaultProps = {
  point: {
    color: '',
    data: {
      studyHours: '',
      date: '',
    },
  },
};

export default ChartTooltip;
