import styled from 'styled-components';

export const StyleMyPlan = styled.div`
  width: 100%;
  max-width: 1224px;
  height: 85%;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  div.right-column {
    display: flex;
    justify-content: space-between;
  }
`;

export const TitleStyled = styled.div`
  margin-bottom: 43px;
  h1 {
    font-size: 32px;
    margin-bottom: 15px;
    line-height: 1;
    font-weight: 800;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    color: #8c8c8c;
    margin: 0;
  }
`;

export const ResumeStyled = styled.div`
  margin: 0 0 25px 0;
  h3 {
    font-family: 'Avenir';
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 1;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: #8c8c8c;
  }
`;

export const BoxContainer = styled.div`
  padding: ${props => props.padding || '20px'};
  background: #ffffff;
  border-radius: 13px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RightPosition = styled.div`
  text-align: right;
`;

export const TitleBox = styled.div`
  font-weight: 800;
  color: #8c8c8c;
  font-size: 12px;
  margin-bottom: 0.2rem;
`;

export const InfoDataBox = styled.span`
  font-weight: 400;
  color: #231f20;
  font-size: 16px;
`;

export const ProgressBarBox = styled.div`
  padding-top: 13px;

  div {
    border-radius: 2.8rem;
    overflow: hidden;
  }
`;

export const SkeletonContainer = styled.div`
  div {
    display: inline-block;
    margin: 0;
    width: calc(50% - 8px);

    :first-child {
      margin-right: 8px;
    }

    :last-child {
      margin-left: 8px;
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 75% auto;
  grid-gap: 10px;
`;

export const GridColumn = styled.div`
  margin: ${props => props.margin};
  width: 100%;
  > div {
    > button {
      width: 100%;
      position: relative;

      > svg {
        margin: 0;
        position: absolute;
        right: 15px;
      }
    }
  }
`;
