import styled from 'styled-components';

export const UserUpdateContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

export const Status = styled.div`
  margin-right: 10px;
`;

export const FlexLink = styled.a`
  display: flex;
  padding: 2px 12px;
  margin-left: 10px;
  cursor: pointer;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(5, 2fr) 1fr 0.9fr;
  border-radius: 14px;
  padding: 15px;
  background: #ffffff;

  > span {
    padding: 15px 0rem;
    padding-top: 10px;
    line-height: 10px;
    color: #a3a3a3;
    font-weight: normal;
    font-size: 0.9rem;
    padding-left: 15px;

    &:last-child {
      padding-right: 15px;
    }
  }

  > div {
    padding: 15px 0rem;
    border-top: 1px solid #e5e5e5;
    font-size: 0.9rem;
    color: #626262;
    text-transform: capitalize;
    padding-left: 15px;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
`;

export const Title = styled.p`
  font-size: 18px;
  color: #000;
`;
