import React from 'react';

import { useDispatch } from 'react-redux';

// Components
import { Button } from '@components';

// Actions
import { actions as modalActions } from '@store/ducks/modal';

// Component Icon
import IconPlus from '@icons/plus.svg';

import { ButtonWrapper } from '../../Styled';

const Actions = () => {
  const dispatch = useDispatch();
  const openModal = () => dispatch(modalActions.open({ type: 'ADD_GROUP' }));

  return (
    <ButtonWrapper>
      <Button variant="primary" leftIcon={<IconPlus />} onClick={openModal}>
        Criar Grupo
      </Button>
    </ButtonWrapper>
  );
};

export default Actions;
