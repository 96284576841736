import Styled, { css } from 'styled-components';

const GoBack = Styled.div`
  display: flex;
  align-items: center;
  justify-content: left;

  & a {
    border: 1px solid #DBDBDB !important;
    &:first-child {
      padding: 5px 10px;
    }
  }

  & svg {
    transform: rotate(90deg);
    transform-origin: 50% 50%;
  }
`;

const Tooltip = Styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  > div {
    margin-right: 19px;
  }

`;

const AchievementTable = Styled.div`
  & tr {

    &:nth-child(even) {
      background-color: #F4F4F4;
    }

    & td,
    & th {
      border-top: none !important;

      &:last-child {
        text-align: right;
      }
    }

    & td {
      &:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      &:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }

  & span {
    text-align: center;
    margin: 30px;
    color: red;
  }
`;

export const SubTitlePage = Styled.div`
   strong {
    color: #231F20
  }
`;

export default AchievementTable;
export { AchievementTable, Tooltip, GoBack };
