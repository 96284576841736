import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

// Components
import { Button, Typography } from '@components';

import IconPlus from '@icons/plus.svg';

// Styles
import { ContainerStep, StepWrapper, ButtonWrapper } from './Styled';

const InitialInstructions = () => {
  const router = useRouter();
  const userReducer = useSelector((state) => state.user);

  const stepGroupCompleted = !!(userReducer?.groups?.length > 0);

  return (
    <>
      <StepWrapper>
        <ContainerStep visible={!stepGroupCompleted}>
          <div>
            <Typography as="h6" weight="bold" dsize="18px" align="center">
              Crie seus grupos para começar
            </Typography>
            <Typography as="p" size="1.2em" align="center">
              Crie grupos para categorizar os usuários de acordo com as esquipes
              em sua organização.
            </Typography>

            <ButtonWrapper>
              <Button variant="primary" onClick={() => router.push('/grupos')}>
                <IconPlus />
                Criar Grupo
              </Button>
            </ButtonWrapper>
          </div>
        </ContainerStep>

        <ContainerStep visible stepsCompleted={!stepGroupCompleted}>
          <div>
            <Typography as="h6" weight="bold" dsize="18px" align="center">
              Adicione seus colaboradores
            </Typography>
            <Typography as="p" size="1.4em" align="center">
              Cadastre seus colaboradores e inclua eles nos seus respectivos
              grupos.
            </Typography>

            <ButtonWrapper stepsCompleted={!stepGroupCompleted}>
              <Button
                variant="primary"
                onClick={() => stepGroupCompleted && router.push('/usuarios')}
                disabled={!stepGroupCompleted}
              >
                <IconPlus />
                Adicionar Usuários
              </Button>
            </ButtonWrapper>
          </div>
        </ContainerStep>
      </StepWrapper>
    </>
  );
};

export default InitialInstructions;
export { InitialInstructions };
