import Styled from 'styled-components';

const BoxStyle = Styled.input`
border-color: inherit;
border-radius: 8px;
background: #edf1f2;
border: 1px solid #e0e0e0;
font-family: 'Avenir';
font-style: normal;
outline: none;
color: #111111;
width: 100%;
display: block;
font-size: 0.9rem;
line-height: 1rem;
height: 6vh;
padding: 0.6rem 15px;

&:active,
&:focus,
&:hover {
  border-color: #fff;
  box-shadow: inset 0 0 6px 0px #e0e0e0;
  border: 1px solid #e0e0e0;
}

&:placeholder {
  color: #a3a3a3;
}
`;

export default BoxStyle;
export { BoxStyle };
