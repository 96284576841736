import Styled from 'styled-components';

const WelcomeText = Styled.div`
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: #8C8C8C;

    > b {
      color: #231F20;
      font-weight: bold;
    }
  }
  
  > p {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #8C8C8C;
  }
`;

const TitleContainer = Styled.div`
  margin-bottom: 52px;
`;

const ContainerTables = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;

  > div {
    flex-grow: 1;
    margin: 0 15px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export { TitleContainer, ContainerTables, WelcomeText };
