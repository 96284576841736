import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

// Template
import { LayoutDefault } from '@templates';

// UTILS
import { setSingularPlural } from '@utils';

// Actions
import { actions as pageSizeActions } from '@store/ducks/pageSize';
import { actions as dashboardActions } from '@store/ducks/dashboard';
import { actions as groupActions } from '@store/ducks/group';

// Styles

// Components
import {
  Title,
  Table,
  Typography,
  Box,
  Pagination,
  TablePagination,
  Margin,
  Button,
  FilterPageSize,
  FilterGroups,
  Search,
  NotFoundData,
  ShortPagination
} from '@components';

// COMPONENT ICON
import ArrowDown from '@icons/arrow-down.svg';
import { AchievementTable, Tooltip, GoBack, SubTitlePage } from './Styled';

const Achievement = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [search, setSearch] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [filterGroups, setFilterGroups] = useState([]);
  const { achievement, loading } = useSelector(
    (state) => state.dashboard.tables
  );

  const setModelDataTable = (arr) => {
    const dataModel = arr?.map((row, index) => ({
      raking: index + 1,
      user: row.fullname,
      steps: setSingularPlural({ count: row.student_has_done, word: 'Módulo' }),
    }));
    return dataModel;
  };

  const getFilterGroups = (arr) => setFilterGroups(arr);

  const resetPageIndex = () => {
    const { pathname, query } = router;
    router.push({
      pathname,
      query: { ...query, page: 1 },
    });
  };

  // const setSortByRanking = (arr) => {
  //   const toNumber = (str) => parseInt(str, 10);

  //   arr?.sort((a, b) => {
  //     if (toNumber(a.student_has_done) > toNumber(b.student_has_done))
  //       return -1;
  //     if (toNumber(a.student_has_done) < toNumber(b.student_has_done)) return 1;
  //     return 0;
  //   });
  //   return arr;
  // };

  // const setSortByAlphabetical = (arr) => {
  //   arr?.sort((a, b) => {
  //     if (a.fullname.toLowerCase() < b.fullname.toLowerCase()) return -1;
  //     if (a.fullname.toLowerCase() > b.fullname.toLowerCase()) return 1;
  //     return 0;
  //   });
  //   return arr;
  // };

  const customSort = (arr) => {
    const toNumber = (str) => parseInt(str, 10);

    arr?.sort((a, b) => {
      if (toNumber(a.student_has_done) > toNumber(b.student_has_done))
        return -1;
      if (toNumber(a.student_has_done) < toNumber(b.student_has_done)) return 1;

      if (a.fullname.toLowerCase() < b.fullname.toLowerCase()) return -1;

      if (a.fullname.toLowerCase() > b.fullname.toLowerCase()) return 1;
      return 0;
    });
    return arr;
  };

  const handleGroupFilter = (arr) => {
    if (filterGroups.length) {
      // let sortRanking = [];
      // let sortAlphabetical = [];
      const filteredWithGroupsFilter = arr?.filter((obj) =>
        filterGroups.includes(obj?.group)
      );
      // const sortByRanking = filteredWithGroupsFilter?.filter((obj) =>
      //   parseInt(obj.student_has_done)
      // );
      // const sortByAlphabetical = filteredWithGroupsFilter?.filter(
      //   (obj) => !parseInt(obj.student_has_done)
      // );
      // if (sortByRanking?.length) {
      //   sortRanking = setSortByRanking(sortByRanking);
      // }
      // if (sortByAlphabetical?.length) {
      //   sortAlphabetical = setSortByAlphabetical(sortByAlphabetical);
      // }
      // const sortList = [...sortRanking, ...sortAlphabetical];
      const sortList = customSort(filteredWithGroupsFilter);
      const dataModel = setModelDataTable(sortList);
      return dataModel;
    }
    return [];
  };

  const newOrderIdRanking = (filtered) => {
    return filtered.map((filter, index) => ({
      ...filter,
      raking: index + 1,
    }));
  };

  const onSearch = (value = search) => {
    setSearch(value);
    if (!filterGroups.length) return [];
    if (!value) {
      const filteredByGroup = handleGroupFilter(achievement);
      return filteredByGroup;
    }
    if (value.length < search.length) {
      let filterData = achievement;
      if (filterGroups.length) {
        filterData = filterData?.filter((obj) =>
          filterGroups.includes(obj?.group)
        );
      }
      const dataModel = setModelDataTable(filterData);
      const filteredOnSearch = dataModel?.filter((obj) =>
        obj?.user?.trim().toLowerCase().includes(value?.toLowerCase())
      );
      const filterById = newOrderIdRanking(filteredOnSearch);
      return filterById;
    }
    const filtered = dataTable?.filter((obj) =>
      obj?.user?.trim().toLowerCase().includes(value?.toLowerCase())
    );

    const filter = newOrderIdRanking(filtered);
    return filter;
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const filtered = onSearch(e.target.value);
    setDataTable(filtered);
  };

  const setDataByGroup = () => {
    const filteredByGroup = handleGroupFilter(achievement);
    setDataTable(filteredByGroup);
  };

  const renderTable = (
    <Table>
      <colgroup>
        <col span="1" style={{ width: '10%' }} />
        <col span="1" style={{ width: '70%' }} />
        <col span="1" style={{ width: '20%' }} />
      </colgroup>
      <thead>
        <tr>
          <th>Ranking</th>
          <th>Usuário</th>
          <th>Conquistas</th>
        </tr>
      </thead>
      <tbody>
        <TablePagination rows={dataTable} loading={loading} />
      </tbody>
    </Table>
  );

  useEffect(() => {
    dispatch(pageSizeActions.getPageSize());
    dispatch(groupActions.getAllGroupsRequest());
    dispatch(dashboardActions.getDashAchievementRequest());
  }, []);

  useEffect(() => {
    resetPageIndex();
  }, [search]);

  useEffect(() => {
    const filteredByGroup = handleGroupFilter(achievement);
    if (search.length) {
      const filteredBySearch = filteredByGroup?.filter((obj) =>
        obj?.user?.toLowerCase().includes(search?.toLowerCase())
      );
      setDataTable(filteredBySearch);
    } else setDataTable(filteredByGroup);
    return resetPageIndex();
  }, [filterGroups]);

  useEffect(() => {
    if (achievement?.length) setDataByGroup();
  }, [achievement]);

  return (
    <LayoutDefault topButton={!!dataTable?.length}>
      <GoBack>
        <Button href="/home" variant="text" leftIcon={<ArrowDown />}>
          Voltar
        </Button>
      </GoBack>
      <Margin top={0.37} />
      <Title
        as="h1"
        size="3.2rem"
        title="Conquistas"
      // subtitle="Veja quais colaboradores que conquistaram medalhas com o Wise Up Online, você também poderá filtrar por grupos."
      />
      <SubTitlePage>
        <Typography size="14px" color="#8C8C8C">
          Veja os colaboradores que completaram módulos no Wise Up Online, você
          também poderá filtrar por grupos. <br />
          A cada <strong>8 módulos</strong> conquistados, seus colaboradores poderão
          emitir um novo
          <strong> certificado.</strong>
        </Typography>
      </SubTitlePage>

      <Margin top={1} />
      <Box>
        <Tooltip>
          <FilterGroups
            getFilterGroups={getFilterGroups}
            getType="array"
            filter="ranking"
          />
          <FilterPageSize />
          <Search
            value={search}
            onSubmit={(e) => e.preventDefault()}
            onChange={handleSearch}
            placeholder="Buscar por nome de usuário"
            withSubmit
          />

          <ShortPagination total={dataTable?.length} rightSide />
        </Tooltip>
        <AchievementTable
          checkPageOne={router.query.page}
          isSearch={search.length == 0 ? false : !!search}
        >
          {!dataTable?.length ? <NotFoundData /> : renderTable}
        </AchievementTable>
      </Box>
      <Margin top={2} />
      <Pagination total={dataTable?.length} />
    </LayoutDefault>
  );
};

export default Achievement;
export { Achievement };
