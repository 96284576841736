import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Templates
import { LayoutDefault } from '@templates';

// Components
import { MarginHome, Skeleton } from '@components';

// Actions
import { actions as dashboardActions } from '@store/ducks/dashboard';
import { actions as groupActions } from '@store/ducks/group';
import { actions as userActions } from '@store/ducks/user';

// Containers
import {
  InitialInstructions,
  Summary,
  ChartStudyHours,
  ChartAccessFrequency,
  ChartCompletedSteps,
  Achievement,
  StudyHours,
  MostAccessedContent,
} from './containers';

// Styles
import { ContainerTables, WelcomeText } from './Styled';

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // FILTER GROUP
    dispatch(userActions.getAllUsersRequest());
    dispatch(groupActions.getAllGroupsRequest());
    // DASHBOARD - WIDGETS
    dispatch(dashboardActions.getDashWidgetsRequest());
    // DASHBOARD - TABLES
    dispatch(dashboardActions.getDashAchievementRequest());
    dispatch(dashboardActions.getDashStudyHoursRequest());
    dispatch(dashboardActions.getDashMostAccessedContentRequest());
  }, []);

  const userReducer = useSelector((state) => state.user);

  const stepUserCompleted = !!(userReducer?.users?.length > 0);

  return (
    <LayoutDefault>
      {Array.isArray(userReducer?.users) ? (
        <>
          <WelcomeText>
            <span>
              <b>Bem vindo ao</b> Wise Up Corporate
            </span>
            <p>Acompanhe o dia a dia de progresso dos seus colaboradores.</p>
          </WelcomeText>
          <MarginHome top={36} />
          {!stepUserCompleted && <InitialInstructions />}
          <Summary />
          <MarginHome top={36} />
          <ChartStudyHours />
          <MarginHome top={36} />
          {stepUserCompleted && (
            <>
              <ChartAccessFrequency />
              <MarginHome top={36} />
              <ChartCompletedSteps />
              <MarginHome top={36} />
              <ContainerTables>
                <Achievement />
                <StudyHours />
              </ContainerTables>
              <MarginHome top={36} />
              <MostAccessedContent />
            </>
          )}
        </>
      ) : (
        <Skeleton width="100%" height="100%" />
      )}
    </LayoutDefault>
  );
};

export default Dashboard;
export { Dashboard };
