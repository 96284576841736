import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Lib
import { ResponsiveLine } from '@nivo/line';

// Components
import { Skeleton } from '@components';
import { ChartPlaceholder } from './../ChartPlaceholder';

// Partials
import ChartTooltip from './ChartTooltip';

// UTILS
import { secondsToHHMMSS, segToHour } from '@utils';

// Settings Chart
const settingsChart = {
  colors: ['#00EB84'],
  tooltip: [{ color: '#00EB84', label: 'Horas de estudos', id: 'studyHours' }],
  margin: { top: 20, right: 20, bottom: 100, left: 70 },
  curve: 'monotoneX',
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickValues: [],
  },
  axisLeft: {
    format: (values) => `${(segToHour(values, false))}`
  },
  enableGridX: false,
  enableGridY: true,
  lineWidth: 2,
  pointSize: 0,
  pointColor: { from: 'color', modifiers: [] },
  pointBorderWidth: 2,
  pointBorderColor: '#555555',
  pointLabelYOffset: 24,
  pointLabel: 'y',
  enableCrosshair: true,
  crosshairType: 'x',
  gridXValues: null,
  gridYValues: null,
  legends: [],
  areaOpacity: 0,
  useMesh: true,
  tooltip: {},
  theme: {
    crosshair: {
      line: {
        stroke: '#00EB84',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDasharray: 0,
        strokeLinecap: 'round',
      },
    },
  },
  xScale: { type: 'point' },
  yScale: {
    type: 'linear',
    reverse: false,
    stacked: true,
    min: 0,
    max: 'auto'
  },
  yFormat: 'time:%H:%M:%S',
};

const Chart = () => {
  const [dataChart, setDataChart] = useState([]);
  const [yScaleMaxValue, setYScaleMaxValue] = useState(1);
  const { studyHours } = useSelector((state) => state.dashboard.graphics);

  const setDateDayAndMonth = (date) => {
    const split = date.split('-');
    return split[2] + '/' + split[1];
  };

  const isEmptyData = () => {
    const { data } = dataChart[0];
    if (!data) return true;
    return Object.entries(data).every(([_k, v]) => v.y === 0)
  };

  const renderChart = () => {
    return (
      <ResponsiveLine
        data={dataChart}
        animate={true}
        margin={settingsChart.margin}
        yFormat={settingsChart.yFormat}
        xScale={settingsChart.xScale}
        yScale={{
          ...settingsChart.yScale
        }}
        curve={settingsChart.curve}
        axisTop={settingsChart.axisTop}
        axisRight={settingsChart.axisRight}
        axisBottom={settingsChart.axisBottom}
        axisLeft={settingsChart.axisLeft}
        enableGridX={settingsChart.enableGridX}
        enableGridY={settingsChart.enableGridY}
        colors={settingsChart.colors}
        lineWidth={settingsChart.lineWidth}
        pointSize={settingsChart.pointSize}
        pointColor={settingsChart.pointColor}
        pointBorderWidth={settingsChart.pointBorderWidth}
        pointBorderColor={settingsChart.pointBorderColor}
        pointLabelYOffset={settingsChart.pointLabelYOffset}
        enableCrosshair={settingsChart.enableCrosshair}
        crosshairType={settingsChart.crosshairType}
        pointLabel={settingsChart.pointLabel}
        gridXValues={settingsChart.gridXValues}
        gridYValues={settingsChart.gridYValues}
        legends={settingsChart.legends}
        useMesh={settingsChart.useMesh}
        areaOpacity={settingsChart.areaOpacity}
        theme={settingsChart.theme}
        tooltip={({ point }) => <ChartTooltip point={point} />}
        defs={[
          {
            id: 'studyHours',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: '#00EB84' },
              { offset: 100, color: '#ffffff' },
            ],
          },
        ]}
        fill={[{ match: '*', id: 'studyHours' }]}
        animate={true}
        enableArea={true}
        areaOpacity={0.2}
      />
    );
  };

  useEffect(() => {
    if (studyHours?.length) {
      const data = studyHours.map((obj) => {
        const shortDate = setDateDayAndMonth(obj.date);
        const time = secondsToHHMMSS(obj.session);
        if (obj.session > yScaleMaxValue) setYScaleMaxValue(obj.session);

        return {
          x: shortDate,
          y: obj.session,
          studyHours: time,
          date: obj.date,
        };
      });

      const setData = [{ id: 'studyHours', data }];
      settingsChart.axisBottom.tickValues = data.map(({ x }) => x);
      setDataChart(setData);
    }
  }, [studyHours]);

  if (!dataChart.length)
    return <Skeleton width="100%" height="20px" rows={10} />;

  return isEmptyData() ? <ChartPlaceholder /> : renderChart();
};

export default Chart;
export { Chart };
